import React, { useState } from "react"
import {
  Heading,
  Paragraph,
  GlassBox,
  Anchor,
  Text,
  Input,
  Box,
  Button as ButtonG,
  Loader,
} from "../commonElements"
import styled from "styled-components"
import LabelWrapper from "./LabelWrapper"
import { useInput } from "../hook/useInput"
import { handleResetPassword } from "../../redux/auth/actions"
import { errorCodeData } from "../../data/firebase-error"

const Container = styled(GlassBox)`
  font-size: 1.8rem;
  max-width: 70rem;
  align-items: center;
  width: calc(100% - 2rem);
`

const InputBox = styled(Box)`
  flex: 2;
  max-width: 80rem;
  width: 100%;
  @media (max-width: 1200px) {
    flex: 100%;
  }
`

const ButtonLoader = styled(Loader)`
  font-size: 5px;
  color: ${props => props.color};
  margin-left: 1.5rem;
`

const Button = styled(ButtonG)`
  width: 100%;
  max-width: 37rem;
  -webkit-tap-highlight-color: transparent;
  cursor: ${props => (props.disabled ? "auto" : "pointer")};
  opacity: ${props => (props.disabled ? 0.2 : 1)};
`

const TextInput = styled(Input)`
  background-color: #f2f2f2;
  border: none;
  border-radius: 0px;
  flex: 1;
  min-width: 28rem;
  min-height: 4rem;
  border-radius: 2px;
  ::-webkit-input-placeholder {
    color: inherit;
    opacity: 0.54;
  }
`

const ParagraphNewline = styled.span`
  @media screen and (max-width: 600px) {
    display: none;
  }
`

const ContainerFooter = ({ success }) => {
  return (
    <Box
      flexDirection="row"
      justifyContent="center"
      whiteSpace="nowrap"
      gap=".5rem"
      positionBox="absolute"
      bottomP="2rem"
    >
      <Text style={{ color: "#D3D3D3", fontSize: "1.6rem" }}>
        {success ? "Go back to" : "For further assistance,"}
      </Text>
      <Anchor
        href={success ? "/" : "/contact/"}
        style={{
          textDecoration: "underline",
          fontWeight: "normal",
          fontSize: "1.6rem",
          color: "#8D8A8A",
        }}
        alt={success ? "flipsimu" : "contact"}
      >
        {success ? "FlipSimu" : "contact us"}
      </Anchor>
    </Box>
  )
}

const ForgotPasswordForm = () => {
  const { emailValue, emailBind, emailReset, emailValid } = useInput(
    "",
    "email",
    {
      isEmail: true,
    }
  )
  const [errors, setErrors] = useState({
    email: "",
    overall: "",
  })
  const [success, setSuccess] = useState(false)
  const [submitting, setSubmitting] = useState(null)

  const submitForm = async () => {
    setErrors({
      email: "",
      overall: "",
    })
    setSuccess(false)
    if (!emailValid().isValid) {
      setErrors({
        email: !emailValid().isValid && emailValid().errorMsg,
        overall: "",
      })
      return
    }
    setSubmitting(true)

    try {
      const result = await handleResetPassword(emailValue)
      const errorCode = result.errorCode
      if (errorCode) {
        if (errorCode === "auth/user-not-found") {
          setErrors({
            email: "Email not found.",
            overall: "",
          })
        } else {
          throw errorCode
        }
      } else {
        setSuccess(true)
        emailReset()
      }
    } catch (errorCode) {
      const error = errorCodeData.filter(data => data.errorCode === errorCode)
      if (error.length === 0) {
        setErrors({
          email: "",
          overall: "There is an error for your submission. Please try again.",
        })
      } else {
        setErrors({
          email: "",
          overall: error[0].errorMessage,
        })
      }
    }
    setSubmitting(false)
  }

  return (
    <Container
      positionBox="relative"
      backgroundColor="white"
      padding="2rem 2rem 3.5rem"
      minHeight="30rem"
    >
      <Heading level={1} textAlign="center" mb=".8rem">
        {success ? "Check your email." : "Recover password."}
      </Heading>

      {success ? (
        <Paragraph level={4} textAlign="center" mt="3.5rem" mb="4.6rem">
          We had sent an account recover instructions to your email.
          <ParagraphNewline>
            <br />
          </ParagraphNewline>
          &nbsp;Please do check your mailbox. Thank you.
        </Paragraph>
      ) : (
        <Paragraph level={4} textAlign="center" mb="3.5rem">
          Enter your signup email to receive a password reset link.
        </Paragraph>
      )}

      {!success && (
        <InputBox>
          <Box positionBox="relative" alignItems="stretch" height="100%">
            <LabelWrapper
              label="Email"
              labelWidth="10.5rem"
              error={errors.email}
            >
              <TextInput {...emailBind} placeholder="Email" type="text" />
            </LabelWrapper>
            <Box
              alignItems="center"
              alignSelf="center"
              margin="4.6rem 0"
              maxWidth="47.5rem"
              width="100%"
              gap="1.5rem"
            >
              <Button disabled={submitting} onClick={submitForm}>
                {
                  <Box
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                    gap="1rem"
                  >
                    <Text>Send Recovery Email</Text>
                    {submitting && <ButtonLoader color="white" />}
                  </Box>
                }
              </Button>
              <Anchor
                href="/login/"
                style={{
                  textDecoration: `underline`,
                  color: "#3d3d3d",
                }}
                alt="login"
              >
                CANCEL
              </Anchor>
              {!!errors.overall && (
                <Text color="reject" fontSize="1.6rem" textAlign="center">
                  {errors.overall}
                </Text>
              )}
            </Box>
          </Box>
        </InputBox>
      )}
      <ContainerFooter success={success} />
    </Container>
  )
}

export default ForgotPasswordForm
