import { useState } from "react"

const capitalize = s => {
  if (typeof s !== "string") return ""
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const useInput = (
  initialValue,
  name,
  validationRules = null,
  callback = null
) => {
  const [value, setValue] = useState(initialValue)

  const checkValidity = (value, rules) => {
    let isValid = true
    let errorMsg = ""
    if (!rules) {
      return true
    }

    if (rules.required && isValid) {
      isValid = value.trim() !== ""
      if (!isValid) errorMsg = `${capitalize(name)} is required.`
    }

    if (rules.minLength && isValid) {
      isValid = value.length >= rules.minLength
      if (!isValid) errorMsg = `Minimum ${name} length >= ${rules.minLength}.`
    }

    if (rules.maxLength && isValid) {
      isValid = value.length <= rules.maxLength && isValid
      if (!isValid) errorMsg = `Maximum ${name} length < ${rules.maxLength}.`
    }

    if (rules.isEmail && isValid) {
      const pattern =
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
      isValid = pattern.test(value) && isValid
      if (!isValid) errorMsg = `Please enter a valid email address.`
    }

    if (rules.isNumeric && isValid) {
      const pattern = /^\d+$/
      isValid = pattern.test(value) && isValid
      if (!isValid) errorMsg = "Is not number."
    }

    if (rules.isPassword && isValid) {
      const passwordPattern = /^(?=.*[0-9])(?=.*[^a-zA-Z0-9]).{8,}$/
      isValid = passwordPattern.test(value) && isValid
      if (!isValid)
        errorMsg = `Must be 8 or more characters and contain at least 1 number and 1 special character.`
    }

    return { isValid, errorMsg }
  }

  return {
    [name + "Value"]: value,
    ["set" + capitalize(name)]: setValue,
    [name.toLowerCase() + "Valid"]: () => checkValidity(value, validationRules),
    [name.toLowerCase() + "Empty"]: value === "" ? true : false,
    [name.toLowerCase() + "Reset"]: () => setValue(initialValue),
    [name.toLowerCase() + "Bind"]: {
      name,
      value,
      onChange: event => {
        setValue(event.target.value)
        callback && callback()
      },
    },
  }
}
