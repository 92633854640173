import React from "react"
import { Text, Box } from "../../components/commonElements"
import styled from "styled-components"

const LabelBox = styled(Box)`
  padding: 0 2rem 0 0;
  @media (max-width: 1200px) {
    padding: 0 0 0.5rem 0;
    flex-grow: 1;
  }
`

const LabelWrapper = ({
  children,
  label,
  labelWidth = "25rem",
  error = "",
  hint = "",
}) => {
  return (
    <Box alignItems="center">
      <Box flexDirection="row" flexWrap="wrap" style={{ columnGap: `1.3rem` }}>
        <LabelBox width={labelWidth} justifyContent="center" height="4rem">
          <Text>{label}</Text>
        </LabelBox>
        <Box positionBox="relative" flex="1 0 auto" width="37rem">
          {children}
          <Text
            color={error ? "reject" : "#707070"}
            fontSize={
              error.length > 35 || hint.length > 35 ? "1.2rem" : "1.6rem"
            }
            style={{
              lineHeight: error.length > 35 || hint.length > 35 ? "1.4" : "1.5",
            }}
          >
            {error ? error : hint}
          </Text>
        </Box>
      </Box>
    </Box>
  )
}

export default LabelWrapper
