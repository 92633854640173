export const errorCodeData = [
  {
    errorCode: "auth/email-already-in-use",
    errorMessage:
      "This email address is already registered. Please try logging in or reset your password.",
  },
  {
    errorCode: "auth/wrong-password",
    errorMessage:
      "The password entered is incorrect. Please try again or reset your password.",
  },
  {
    errorCode: "auth/weak-password",
    errorMessage:
      "The password you entered is too weak. Please choose a stronger password.",
  },
  {
    errorCode: "auth/user-not-found",
    errorMessage:
      "The email address you entered was not found. Please try again or sign up for a new account.",
  },
  {
    errorCode: "auth/user-disabled",
    errorMessage:
      "Your account has been disabled. Please contact support for further assistance.",
  },
  {
    errorCode: "auth/popup-blocked",
    errorMessage: "Please disable your browser's popup blocker and try again.",
  },
  {
    errorCode: "auth/cancelled-popup-request",
    errorMessage:
      "Please try again and make sure to complete the process without cancelling the popup window.",
  },
  {
    errorCode: "auth/popup-closed-by-user",
    errorMessage:
      "Please try again and make sure to keep the popup window open until the process is complete.",
  },
  {
    errorCode: "auth/invalid-email",
    errorMessage:
      "The email entered is invalid. Please check for typos and try again.",
  },
]
