import React from "react"
import Layout from "../layout/GeneralLayout"
import { Box } from "../components/commonElements"
import styled from "styled-components"
import Footer from "../components/footer"
import SEO from "../components/seo"
import ForgotPasswordForm from "../components/accountForm/forgotPasswordForm"

const OuterContainer = styled(Box)`
  width: 100%;
  align-items: center;
  min-height: 78vh;
  @media ${props => props.theme.device.tablet} {
    min-height: 83vh;
  }
`

const forgotpassword = () => {
  return (
    <Layout>
      <Box flex="1" mt="1rem" alignItems="center" gap="1rem">
        <OuterContainer>
          <ForgotPasswordForm />
        </OuterContainer>
        <Footer />
      </Box>
    </Layout>
  )
}

export default forgotpassword

export const Head = () => (
  <SEO
    titleP="Forgot Password | FlipSimu"
    descriptionP="This is the forgot password page of FlipSimu"
    pathname="/forgot-password/"
  />
)
